@use 'sass:math';

@function strip-unit($num) {
    @return math.div($num, ($num * 0 + 1));
}

@mixin setProperty($imp:"", $property, $values...) {
    $max: length($values);
    $pxValues: '';
    $remValues: '';
    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $pxValues: #{$pxValues + $value}px;
        @if $i < $max {
            $pxValues: #{$pxValues + ' '};
        }
    }
    @for $i from 1 through $max {
        $value: strip-unit(nth($values, $i));
        $remValues: #{$remValues + calc($value/16)}rem;
        @if $i < $max {
            $remValues: #{$remValues + ' '};
        }
    }

    #{$property}: $pxValues unquote($imp);
    #{$property}: $remValues unquote($imp);
}

@mixin setIe11Polyfill($contrast1: "none", $contrast2: "active") {
    @media all and (-ms-high-contrast: $contrast1), (-ms-high-contrast: $contrast2) {
        @content;
    }
}

@mixin center($position) {
    position: absolute;

    @if $position == 'vertical' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    } @else if $position == 'horizontal' {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
    } @else if $position == 'both' {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@import '../mixins/utils';
@import '../global/fontVariables';
@import '../mixins/fontProp';

//custom size guide media query sizes
$sizeGuide992px: 992px;
$sizeGuide767px: 767px;
$sizeGuide576px: 576px;
$sizeGuide1023px: 1023px;

//TODO: wrap entire thing in redesign-size-guide
.size-guide-modal {
    .modal-content {
        background: #fafafa !important;

        .modal-header {
            padding-top: 15px !important;

            .icon-logo {
                display: none;
            }

            .close {
                top: 5px;
                padding: 0 16px;
            }
        }
    }
}

.size-guide-redesign {
    padding-bottom: 40px;

    .fit-description,
    .size-tab-content {
        color: #000;
        @include fontSize($font-body1);

        font-weight: 400;
        line-height: 24px;
        display: block;
    }

    .size-tab-divider {
        background-color: #d3d5d7;
        margin: 32px 0 24px;
        height: 1px;
        border-top: 0;
    }

    .fit-description:has(.shoe-descr) {
        color: #000;
    }

    .size-tab-content {
        color: #000;
    }

    .fit-sub-description {
        a {
            color: #000;
            font-weight: 400 !important;
            border-bottom: 1px solid #d8d8d8 !important;
            padding-bottom: 0 !important;
            @include fontSize($font-body1, !important);

            &:hover {
                border-bottom: 1px solid #d31334 !important;
                text-decoration: none;
            }
        }
    }

    .fit-subheading {
        @include fontSize($font-medium);

        font-weight: 600;
        line-height: 32px;
        display: block;
        margin-top: 10px;
    }

    .fit-sub-description {
        color: #000;
    }

    .measurement-centimeters,
    .measurement-inches {
        @include fontSize($font-body2);

        font-weight: 400;
        padding-top: 0;
        color: #6b6f74;
    }

    .measurement-centimeters {
        padding-right: 1px;
    }

    .measurement-inches {
        padding-left: 1px;
    }

    .measurement-centimeters.active,
    .measurement-inches.active {
        font-weight: 600;
        color: #000;
        padding-left: 0;
        padding-right: 0;
    }

    .measurement-div {
        padding-left: 9px;
        padding-right: 9px;
        float: left;
        position: relative;
        z-index: 1;
    }

    .switch {
        position: relative;
        display: inline-block !important;
        width: 32px;
        height: 18px;
        margin-bottom: 0;

        .toggle-checkbox {
            opacity: 0;
            width: 0;
            height: 0;

            &.tabbed {
                ~ .slider {
                    outline: 2px solid;
                    outline-offset: 2px;
                }
            }
        }
    }

    .measurement-list {
        margin-top: 20px;
        margin-bottom: 24px;
        line-height: 1.1;
    }

    .measurement-list .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider::before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        top: 2px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        background-position: center 6.4px !important;
        background-repeat: no-repeat !important;
    }

    input.toggle-checkbox:checked + .slider {
        background-color: #000;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider::before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
    }

    .slider.round {
        border-radius: 46px;
    }

    .slider.round::before {
        border-radius: 100%;
    }

    .asia-fit-icon-header {
        p {
            font-weight: 500;
            @include fontSize($font-xsmall);
        }
    }

    .no-toggle-fit,
    .no-toggle-pdp {
        padding-top: 25px;
    }

    .category-tablist-div {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        .nav-item {
            background-image: linear-gradient(#e8e7e4, #e8e7e4);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 2px !important;
        }
    }

    .content-asset {
        .base-img {
            width: 100%;
            max-width: 300px;
        }

        ol > li,
        .image-heading {
            @include fontSize($font-body1);

            line-height: 24px;
            margin-bottom: 4px;
        }

        @media only screen and (min-width: $sizeGuide767px) {
            .asiafit-bra-content {
                padding-top: 0;
            }
        }
    }

    @media only screen and (min-width: $sizeGuide992px) {
        .modal-content {
            padding-bottom: 50px !important;
        }
    }

    @media only screen and (max-width: $sizeGuide576px) {
        .sizechart .size-table th,
        .sizechart .size-table td {
            padding: 0 !important;
        }

        .size-chart .search-table {
            background: #fff;
        }

        .content-asset {
            .base-img {
                width: 90%;
            }

            ol > li,
            .image-heading {
                @include fontSize($font-body2);

                line-height: 20px;
            }

            ol {
                padding-left: 10px;
            }

            .global-fit-text {
                text-align: right;
                padding-right: 40px;
            }

            .asia-fit-text {
                text-align: left;
                padding-left: 40px;
            }
        }
    }

    @media only screen and (max-width: $sizeGuide767px) {
        .tab-content-top {
            padding-top: 10px;
        }

        .asia-fit-icon-header {
            p {
                @include fontSize($font-body1);
            }
        }
    }

    .myMeasureTab .search-table {
        table-layout: unset;
        margin: 0 auto;
    }

    .myMeasureTab .size-table th,
    .myMeasureTab .size-table td {
        min-width: 111px;
        text-align: center;
        height: 55px;
        line-height: 12px;
    }

    @media only screen and (min-width: $sizeGuide576px) {
        .myMeasureTab .search-table-outter {
            overflow-x: scroll;
            width: auto;
            max-width: 100% !important;
            margin-left: 0;
            margin-right: auto;
            padding-bottom: 7px;
        }

        .myMeasureTab .headcol {
            position: sticky;
            left: 0;
            top: auto;
        }

        .bootstrap-select.intl-dropdown {
            max-width: 337px !important;
        }
    }

    .sizechart .size-h2 {
        margin: 15px 0;
    }

    .sizeguide-region-selector {
        margin-top: 32px;
    }

    .myMeasureTab .size-table td,
    .myMeasureTab .size-table th {
        height: 55px;
    }

    .size-guide-modal .nav-tabs .nav-item .nav-link {
        @include fontSize($font-body2);

        font-weight: 400;
        line-height: 20px;
    }

    .measure-subhead {
        margin-bottom: 20px !important;
    }

    .myMeasureTab .size-table td,
    .myMeasureTab .size-table th {
        @include fontSize($font-body2, !important);
    }

    .size-table {
        tr.even {
            td {
                background: #fafafa;
            }

            th {
                &:first-child {
                    background: #f2f2f2;
                }
            }
        }

        tr.odd {
            td {
                background: #f1f1f2;
            }

            th {
                &:first-child {
                    background-color: #e6e6e6;
                }
            }
        }

        tr.odd ~ tr.nth-child-middle {
            td {
                background: #f1f1f2;
            }
        }

        tr.even ~ tr.nth-child-middle {
            td {
                background: #fafafa;
            }
        }

        td {
            vertical-align: middle;
        }
    }

    .measure-desc {
        a.book-link {
            @include fontSize($font-body1);

            font-weight: 400;
            padding-bottom: 0;
            border-bottom: 1px solid #57585b;

            &:hover {
                border-bottom: 1px solid #d31334;
            }
        }
    }

    .size-guide-modal .nav-item .nav-link:hover {
        background-color: #fafafa !important;
    }

    .dropdown-heading {
        @include fontSize($font-xsmall-desk);

        line-height: 24px;
        margin-bottom: 10px;
        display: block;
        text-align: left;
        font-weight: 600;
    }

    .return-policy,
    .live-chat {
        background-color: #fff;
    }

    .other-information .hover-underline::after {
        display: block;
        content: "";
        height: 4px;
        width: 24px;
        background: #d31334;
        margin-top: 7px;
    }

    .myDivs {
        display: none;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: transparent;
        color: unset;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: unset;
    }

    .sizechart .nav-tabs {
        justify-content: left !important;
        padding: 0;
    }

    .btn.dropdown-toggle.btn-light {
        background-color: #fff;
        color: #000;
        border: 1px solid #000;
        border-radius: 4px;
    }

    .dropdown-toggle::before {
        border: none;
        background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2Ij4gICAgPHBhdGggZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTkuNDEyLjg4Mkw1IDUuMjk0LjU4OC44ODIiLz48L3N2Zz4=) no-repeat right;
        height: 7px;
        width: 14px;
        vertical-align: middle;
        background-position: unset;
    }

    .intl-dropdown.show .dropdown-toggle::before {
        transform: rotate(180deg);
    }

    .btn-light:not(:disabled):not(.disabled):active:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
        box-shadow: unset;
    }

    .bootstrap-select .dropdown-menu li {
        padding: 15px 0;
        margin: 0 13px;
        border-bottom: 1px solid #e0e0e0;
    }

    .bootstrap-select .dropdown-menu.show {
        padding-top: 0;
        margin-top: 4px;
        border: 1px solid #000;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.45);
    }

    .bootstrap-select .dropdown-menu.inner.show {
        border: 0;
        box-shadow: none;
    }

    .bootstrap-select .dropdown-menu .inner ul.dropdown-menu li:last-child {
        border: none;
    }

    .bootstrap-select .dropdown-menu .dropdown-item {
        border-bottom: unset !important;
        padding-left: 8px;
        @include fontSize($font-body1, !important);

        font-weight: 400 !important;
    }

    .inner ul.dropdown-menu li:hover {
        background-color: #e6e6e6;
    }

    .inner ul.dropdown-menu li.active {
        background-color: #e6e6e6;
        outline: 1px solid -webkit-focus-ring-color !important;
    }

    .dropdown-menu.show .inner.show::-webkit-scrollbar {
        width: 8px;
    }

    .dropdown-menu.show .inner.show::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
    }

    .intl-dropdown {
        .filter-option-inner-inner {
            @include fontSize($font-text);

            line-height: 48px !important;
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .bootstrap-select.show .filter-option {
        box-shadow: 0 0 6px 0 #00000073;
    }

    .ctaArrow {
        color: #d31334;
        margin-left: 3px;
        transition: 0.2s ease-in;
    }

    .l2ctaArrow:hover .ctaArrow {
        transform: translateX(50%);
    }

    .l2ctaArrow:hover .return-policy-link,
    .l2ctaArrow:hover .live-chat-link {
        border-bottom: 1px solid #d31334 !important;
        text-decoration: none;
    }

    .live-chat .measure,
    .return-policy .measure {
        @include fontSize($font-xsmall-desk);
    }

    .truefit-space {
        background: transparent;
        min-height: 40px;
    }

    .centimeter,
    .inches {
        margin-bottom: 24px;
    }

    .fit-tab-div.active .content-asset,
    .tab-content-top .content-asset {
        margin-top: 40px;
    }

    /** Box Shadow Style **/
    .shadow-divider {
        height: 55px;
        position: absolute;
        top: 0;
        right: -12px;
        width: 12px;
        background: linear-gradient(90deg, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.15;
        height: 100%;
    }

    /* Bugfix css */
    .return-policy-link,
    .live-chat-link {
        text-decoration: none;
        font-weight: 400 !important;
        border-bottom: 1px solid #d8d8d8 !important;
        @include fontSize($font-body2, !important);
    }

    .icon.icon-logo {
        display: none !important;
    }

    .size-table th,
    .size-table td {
        border: 0 !important;
        border-right: 1px solid #fff !important;
    }

    .headcol {
        font-weight: 500 !important;
    }

    .search-table-outter {
        background-color: #fafafa;
    }

    .intl-dropdown {
        width: 100% !important;
        border: 1px solid #000;
        border-radius: 4px;
        background: none;
        left: 0;
    }

    .intl-dropdown .btn-light.dropdown-toggle {
        background-color: unset;
        position: initial;
        color: #000;
        border: none;
        outline: none !important;
        box-shadow: none;
        margin: unset;
        max-width: unset;
        float: unset;

        &:focus {
            outline: 2px solid -webkit-focus-ring-color !important;
        }
    }

    .bootstrap-select .dropdown-menu .gradient-shadow {
        position: absolute;
        width: 95%;
        height: 80px;
        background: linear-gradient(to bottom, transparent, #fff);
        bottom: 0;
        pointer-events: none;
    }

    .intl-dropdown.dropdown .dropdown-toggle::after {
        display: none !important;
    }

    .intl-dropdown.show .dropdown-toggle::after {
        display: none !important;
    }

    .intl-dropdown.dropup .dropdown-toggle::after {
        display: none !important;
    }

    .intl-dropdown .dropdown-menu.show {
        max-height: 335px !important;
    }

    .asia-fit-image-womens-bottoms {
        width: 100%;
    }

    .white-card {
        padding-bottom: 0;
    }
    @media only screen and (max-width: $sizeGuide576px) {
        .intl-dropdown .dropdown-menu.show {
            max-height: 250px !important;
        }

        .white-card {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 0;
        }

        .other-information .measure-desc,
        .sizechart .measure-subhead {
            @include fontSize($font-body1, !important);
        }

        .live-chat .measure-desc,
        .return-policy .measure-desc {
            line-height: 20px !important;
        }

        .live-chat .measure,
        .return-policy .measure,
        .other-information .measure-desc {
            line-height: 24px !important;
        }

        .shadow-divider-first {
            height: 110px;
        }

        .intl-dropdown .filter-option-inner-inner {
            @include fontSize($font-text);

            font-weight: 400;
            letter-spacing: 0;
        }

        .default-selected .filter-option-inner-inner {
            color: #57585b;
        }
    }

    .size-h1 {
        line-height: 48px !important;
        @include fontSize($font-medium-desk, !important);
    }

    @media only screen and (max-width: $sizeGuide1023px) {
        .size-h1 {
            line-height: 44px !important;
            @include fontSize($font-large, !important);
        }

        .size-h2,
        .fit-subheading {
            line-height: 28px !important;
            @include fontSize($font-small, !important);
        }
    }

    .table-shadow {
        position: relative;
    }

    .table-shadow:has(.showUSwomensocks),
    .table-shadow:has(.showUSmensocks) {
        &::after {
            bottom: 7px !important;

            @media only screen and (max-width: $sizeGuide576px) {
                bottom: 8px !important;
            }
        }

        .search-table-outter {
            overflow-x: auto;
        }
    }

    .table-shadow:has(.showUSmensocks) {
        .search-table-outter {
            margin-right: unset;
        }
    }

    .table-shadow::after {
        content: "";
        position: absolute;
        right: 0;
        background: -moz-linear-gradient(right, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(217, 217, 217, 0)), color-stop(100%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(right, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(right, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(right, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%); /* IE10+ */
        background: linear-gradient(270deg, #000 0%, rgba(217, 217, 217, 0) 100%, rgba(0, 0, 0, 0) 100%);
        width: 12px;
        top: 0;
        bottom: 24px;
        opacity: 0.15;
        transform: translate(-2%, 0%);
        @media only screen and (max-width: $sizeGuide767px) {
            bottom: 8px;
        }
    }

    .country-dropdown-label {
        color: #000;
        display: block;
    }

    .country-intl-selector {
        .dropdown-menu.inner.show {
            .selected.active {
                display: none;
            }
        }
    }
}

.sizeguide-tooltip.bs-tooltip-bottom,
.sizeguide-tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding-top: 0.8rem;
    padding-left: 13px;
}

.sizeguide-tooltip.bs-tooltip-top,
.sizeguide-tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding-bottom: 0.8rem;
    padding-left: 13px;
}

.underband-tooltip {
    .icon {
        margin-bottom: 5px;
    }
}

.sizeguide-tooltip.tooltip.show {
    opacity: 1;
}

.sizeguide-tooltip {
    .tooltip-inner {
        max-width: 208px;
        background-color: #fff;
        color: #000;
        border: 1px solid black;
        border-radius: 0.25rem;
    }
}

.sizeguide-tooltip.bs-tooltip-bottom {
    .arrow {
        &::before {
            bottom: -0.45rem;
            left: -2px;
            border-bottom-color: #000;
            border-width: 0 0.6rem 0.4rem;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -0.35rem;
            left: -2px;
            border-width: 0 0.6rem 0.4rem;
            transform: translateY(3px);
            border-color: transparent;
            border-style: solid;
            border-bottom-color: #fff;
        }
    }
}

.sizeguide-tooltip.bs-tooltip-top {
    .arrow {
        &::before {
            top: -0.49rem;
            left: -2px;
            border-bottom-color: #000;
            border-width: 0.4rem 0.6rem 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: -0.72rem;
            left: -2px;
            border-width: 0.4rem 0.6rem 0;
            transform: translateY(3px);
            border-color: transparent;
            border-style: solid;
            border-top-color: #fff;
        }
    }
}

.size-h1 {
    line-height: 56px !important;
    @include fontSize($font-large-desk, !important);
}

.size-h2 {
    line-height: 48px !important;
    margin-bottom: 8px !important;
    @include fontSize($font-medium-desk, !important);
}

@media only screen and (max-width: $sizeGuide1023px) {
    .size-h1 {
        line-height: 44px !important;
        @include fontSize($font-large, !important);
    }
}

.category-tablist-div {
    .category-tabs {
        border-bottom: none;
        padding-left: 0;
        justify-content: unset !important;
        white-space: nowrap;
        flex-wrap: inherit;

        .nav-item {
            .nav-link {
                margin: 0 25px 0 0;
                color: #000;
                background-color: transparent;
                display: inline-table;
                padding: 0;
                line-height: 17px;
                text-transform: capitalize;
                font-weight: 400;
                background-color: initial;
                @include fontSize($font-body2);

                &.active {
                    font-weight: 600;
                    height: 30px;
                    border-bottom: 0;
                    text-decoration: none;
                    background-image: linear-gradient(#d22030, #d22030);
                    background-position: 0 100%;
                    background-repeat: no-repeat;
                    background-size: 100% 2px !important;
                    background-color: initial;
                }
            }
        }
    }

    hr {
        height: 2px !important;
        background-color: #e8e7e4;
        margin-top: -2px !important;
        margin-bottom: 15px;
        border-top: 0;
    }
}

.sizechartFooter {
    max-width: 780px;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    background: #fafafa;
    margin-top: 20px;

    #exTab1,
    .search-table-outter {
        background: #fafafa;
    }

    #exTab1 .nav-pills {
        li {
            margin-bottom: 10px;
            margin-right: 10px;

            a {
                padding: 10px 16px;
                text-decoration: none;
                @include fontSize($font-body2);

                display: block;
                text-transform: capitalize;
                border: 1px solid #d5d5d5;
                border-radius: 4px;
                color: #57585b;

                &.active {
                    background-color: #d31334;
                    color: #fff;

                    &:hover {
                        background-color: #911620;
                    }
                }

                &:active {
                    background-color: #911620;
                }

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
}

html {
    &:lang(ja),
    &:lang(ko),
    &:lang(zh) {
        .sizechartFooter {
            .size-h1 {
                font-weight: 700;
            }
        }

        .size-guide-redesign {
            .category-tablist-div {
                .category-tabs {
                    .nav-item {
                        .nav-link {
                            font-size: 16px;
                        }
                    }
                }
            }

            .fit-description,
            .size-tab-content {
                font-size: 16px;
            }

            .asia-fit-container {
                .asia-fit-icon-header {
                    p {
                        font-weight: 600;
                    }
                }
            }

            .asia-fit-text {
                font-size: 16px;
                font-weight: 400;
            }

            .myMeasureTab {
                .size-table {
                    th {
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }

                    td {
                        font-size: 16px !important;
                        line-height: 24px !important;
                    }
                }

                .other-information {
                    .measure {
                        font-weight: 600;
                    }

                    .measure-desc {
                        font-size: 16px !important;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }

            .return-policy {
                .measure {
                    font-weight: 600;
                }

                .measure-desc,
                .return-policy-link {
                    font-size: 16px !important;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }
}

html {
    &:lang(ja),
    &:lang(ko) {
        .size-guide-redesign {
            .showunderband {
                th:first-child {
                    min-width: 153px !important;
                    max-width: 153px !important;
                }
            }
        }
    }
}

.size-guide-redesign {
    .scroll-tab-container {
        padding: 0;
        margin: 20px 0;
        position: relative;
    }

    .scroll-tabs {
        display: flex;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .scroll-tab {
        flex: 0 0 auto;
        min-width: 100px;
        cursor: pointer;
        margin-right: 5px;
    }

    .arrow {
        cursor: pointer;
        padding: 5px;
        color: #000;
        display: block;
        width: 34px;
    }

    .arrow.disabled {
        background-color: transparent;
        cursor: none;
        display: none;
    }

    .scroll-tabs {
        overflow-x: auto;
    }

    @media only screen and (max-width: $sizeGuide767px) {
        .scroll-tab-container {
            margin-top: 15px;
        }
    }

    .scroll-left-arrow::before,
    .scroll-right-arrow::before {
        content: "" !important;
        width: 16px;
        height: 16px;
        display: inline-block;
        opacity: 1;
    }

    .scroll-left-arrow {
        position: absolute;
        left: 0;
        top: 0;
        text-align: left;
        background: linear-gradient(89deg, #fafafa 74.76%, rgba(255, 255, 255, 0) 98.79%);
    }

    .scroll-right-arrow {
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        background: linear-gradient(-89deg, #fafafa 74.76%, rgba(255, 255, 255, 0) 98.79%);
    }

    .scroll-left-arrow::before {
        background: url('../../images/LeftChevron.svg') no-repeat center;
    }

    .scroll-right-arrow::before {
        background: url('../../images/RightChevron.svg') no-repeat center;
    }

    .nav-link {
        padding: 4px 0 0 !important;

        &:hover {
            isolation: auto;
        }
    }

    .nav-tabs {
        padding: 0;
    }
}

#womenbras #womenbrasasia-fit-size-3 {
    .measurement-list {
        visibility: hidden;
        margin-top: -6px;
        margin-bottom: 0;
    }
}

#womens-bra-size-guide #asia-fit-fit-size-3 {
    .measurement-list {
        visibility: hidden;
        margin-top: -6px;
        margin-bottom: 0;
    }
}
